<template>
  <div
    class="pt-12 grey lighten-3"
    style="height:calc(100% - 50px);overflow:auto"
    ref="card"
  >
    <v-card class="ma-4 py-2">
      <div class="px-4">
        <v-sheet
          color="primary"
          width="100%"
          elevation="6"
          class="px-4 py-2 v-card-material mb-n6 rounded"
          dark
        >
          <div class="v-card-material-title pt-1">
            杰瑞系统历史记录
          </div>
          <div class="v-card-material-subtitle py-1">
            腹透远程系统
          </div>
        </v-sheet>
      </div>
      <div
        elevation="6"
        class="mt-4 pa-4 details mx-2"
        v-if="items.length === 0"
      >
        <div class="primary--text text-center">暂无数据</div>
      </div>
      <v-list-item-group v-model="selected" active-class="primary--text">
        <template v-for="(item, index) in items">
          <v-list-item :key="item.index" @click="dialog = true">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  净超滤量（ml）:{{ item.TOTALUFVOL }}
                </v-list-item-title>

                <v-list-item-subtitle class="grey--text pt-1">
                  总注入量（ml）:{{ item.TOTALFILLVOL }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon v-if="!active" color="grey lighten-1">
                  mdi-book-account-outline
                </v-icon>

                <v-icon v-else color="primary">
                  mdi-book-account
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
        </template>
      </v-list-item-group>
      <v-dialog v-model="dialog">
        <v-list flat>
          <v-subheader class="primary--text">历史记录</v-subheader>
          <HistoryItem
            v-for="item in localList"
            :key="item.cn"
            :icon="item.icon"
            :cn="item.cn"
            :title="selectHistory && selectHistory[item.title]"
          />
        </v-list>
      </v-dialog>
      <PreCheck />
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
import HistoryItem from "@/components/Local/HistoryItem.vue";
export default {
  name: "History",
  components: { PreCheck, HistoryItem },
  data: () => ({
    selected: undefined,
    items: [],
    dialog: false,
    localList: [
      { icon: "mdi-phone", cn: "手机号码", title: "PHONE" },
      { icon: "mdi-card-account-details", cn: "身份证", title: "IDCARD" },
      { icon: "mdi-beaker", cn: "净超滤量（ml）", title: "TOTALUFVOL" },
      { icon: "mdi-beaker", cn: "总注入量（ml）", title: "TOTALFILLVOL" },
      { icon: "mdi-beaker", cn: "周期引流量（ml）", title: "BEFOREDRAINVOL" },
      { icon: "mdi-beaker", cn: "总引流量（ml）", title: "TOTALDRAINVOL" },
      { icon: "mdi-beaker", cn: "末袋注入量（ml）", title: "LASTFILLVOL" },
      { icon: "mdi-beaker", cn: "平均置留时间（min）", title: "AVGDWELLTIME" },
    ],
    page_number: 1,
    page_size: 10,
    enableLoadMore: false,
  }),
  watch: {
    dialog(val) {
      !val && (this.selected = undefined);
    },
  },
  computed: {
    ...mapState(["openId", "currentUser"]),
    selectHistory() {
      return this.items[this.selected];
    },
  },
  created() {
    this.currentUser && this.postGet_Squantiey();
  },
  methods: {
    postGet_Squantiey(call) {
      this.loading = true;
      this.$api
        .postGet_SMFT({
          // page_number: this.page_number,
          // page_size: this.page_size,
          IDCARD: this.currentUser.IDCARD,
          // IDCARD: "412727199012012322",
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            let history = JSON.parse(data.data || "[]");
            this.items = history;
            console.log(history);
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
          call && call();
        });
    },
    loadMore(call) {
      this.page_size += 10;
      this.postGet_Sflowup(call);
    },
  },
};
</script>
<style scoped>
.v-card-material {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
.v-card-material-title {
  font-size: 1.125rem;
}
.v-card-material-subtitle {
  font-size: 0.875rem;
  color: #ffffffaa;
}
.m_black {
  font-size: 16px;
  color: #000000a0;
}
.bg_4 {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
</style>
